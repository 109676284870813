import Image from 'next/image'


import CSS from './about.module.css'

export const About = ({ hero, children }) => {

    return (
        <section className={CSS.layout}>
            <div className={CSS.hero}>
                <Image src={hero} layout={`fill`} alt="" />
            </div>

            <div className={CSS.content}>
                { children }
            </div>

            <div className={CSS.filler}>
            </div>
        </section>
    )
}