import { useRef, useEffect, useState, useContext } from "react"
import {  motion, useInView, useScroll, useTransform  } from 'framer-motion'

import { Fade } from "../../../../animation/animation"
import { Reveal } from "../../../../animation/reveal"

import Image from 'next/image'

import CSS from './above-the-fold.module.css'

export function AboveTheFold ({ children, theme }) {

    // animation

    const ref = useRef(null)
    const isInView = useInView(ref)


    let { scrollYProgress } = useScroll({ 
        target: ref,
        offset: ["start end", "end start"]
    })

    let dotsY = useTransform( scrollYProgress, [0, 1], [`-20vw`, `200vw`])

    let y = useTransform( scrollYProgress, [0, 1], [600, -300])

    let rotate = useTransform( scrollYProgress, [0, 1], [-10, -45]) 


    const swooshVariants = {
        hidden: {
            y: '100%'
        },
        visible: {
            y: 0
        }
    }

    return (
        <section className={`${CSS.container} ${ theme ? theme : `bg-primary`}`} >
            <div className={CSS.layout}>

                <section className={CSS.swoosh}>
                    <Fade>
                        <motion.img src="/assets/theme/2025/dot-cloud.png" alt="" style={{ y: dotsY, position: 'absolute', zIndex: 0, left: 0, top: '-20%', opacity: .1 }} />
                    </Fade>

                    <div className={CSS.swooshContainer}>
                        <motion.img 
                            variants={swooshVariants}
                            initial='hidden'
                            whileInView='visible'
                            viewport={{ once: false }}
                            transition={{ type: "spring", bounce: 0, delay: .3 }}
                            className={CSS.swooshLayer} src="/assets/theme/2025/swoosh/layer-1.svg" alt="" 
                        />
                        <motion.img 
                            variants={swooshVariants}
                            initial='hidden'
                            whileInView='visible'
                            viewport={{ once: false }}
                            transition={{ type: "spring", bounce: 0, delay: .25 }}
                            className={CSS.swooshLayer} src="/assets/theme/2025/swoosh/layer-2.svg" alt="" 
                        />
                        <motion.img 
                            variants={swooshVariants}
                            initial='hidden'
                            whileInView='visible'
                            viewport={{ once: false }}
                            transition={{ type: "spring", bounce: 0, delay: .2 }}
                            className={CSS.swooshLayer} src="/assets/theme/2025/swoosh/layer-3.svg" alt="" 
                        />
                        <motion.img 
                            variants={swooshVariants}
                            initial='hidden'
                            whileInView='visible'
                            viewport={{ once: false }}
                            transition={{ type: "spring", bounce: 0, delay: .15 }}
                            className={CSS.swooshLayer} src="/assets/theme/2025/swoosh/layer-4.svg" alt="" 
                        />
                        <motion.img 
                            variants={swooshVariants}
                            initial='hidden'
                            whileInView='visible'
                            viewport={{ once: false }}
                            transition={{ type: "spring", bounce: 0, delay: .1 }}
                            className={CSS.swooshLayer} src="/assets/theme/2025/swoosh/layer-5.svg" alt="" 
                        />
                        <motion.img 
                            variants={swooshVariants}
                            initial='hidden'
                            whileInView='visible'
                            viewport={{ once: false }}
                            transition={{ type: "spring", bounce: 0, delay: .05 }}
                            className={CSS.swooshLayer} src="/assets/theme/2025/swoosh/layer-6.svg" alt="" 
                        />
                        <motion.img 
                            variants={swooshVariants}
                            initial='hidden'
                            whileInView='visible'
                            viewport={{ once: false }}
                            transition={{ type: "spring", bounce: 0, delay: 0 }}
                            className={CSS.swooshLayer} src="/assets/theme/2025/swoosh/layer-7.svg" alt="" 
                        />
                    </div>
                </section>

                <section className={`${CSS.content} block`}>
                    { children }
                </section>

            </div>
        </section>

    )
}