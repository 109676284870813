import Image from 'next/image'

import { Pop } from '../../../../../animation/animation'

import Icon from './img/quotes.svg'
import CSS from './montage.module.css'


export const MontageGrid = ({ children }) => {
    return (
        <div className={`${CSS.gridLayout} bg-primary`}>
            <div className={CSS.graphicMain}>
                <img src={`/assets/theme/2025/montage-swoosh.svg`} />
                {/* <img className={CSS.mobile} src={`/assets/theme/2025/hero-swoosh-mob.svg`} /> */}
            </div>
            <div className={CSS.graphicSecondary}>
                
            </div>
            { children }
        </div>
    )
}


export const MontageSpacer = ({ children, backgroundColor, span }) => {
    return (
        <div className={`${CSS.spacer} ${ span === 2 && CSS.double}`} style={{ backgroundColor: backgroundColor }}>
            { children }
        </div>
    )
}


export const MontageImage = ({ src, width, height, span }) => {
    return (
        <div className={`${CSS.imageContainer} ${ span === 2 && CSS.double}`}>
            <Pop>
                <Image src={src} width={width} height={height} layout={`responsive`} alt="" />
            </Pop>
        </div>

    )
}


export const MontageBox = ({ children, span }) => {
    return (
        <div className={`${CSS.box} ${ span === 2 && CSS.double}`} >
            { children }
        </div>
    )
}


export const MontageQuote = ({ children, span }) => {
    return (
        <div className={`${CSS.quoteContainer} ${ span === 2 && CSS.double}`} >
            <div className={CSS.iconBox}>
                <Icon className={CSS.icon}/>
            </div>
            { children }
        </div>
    )
}