import Image from 'next/image'

import { Pop } from '../../animation/animation'

import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide'
import '@splidejs/react-splide/css'

import CSS from './logo-box-carousel.module.css'

export const LogoBoxCarousel = ({ heading, sponsors }) => {

    if ( !sponsors.length ) return 

    return ( 
        <section className={CSS.layout}>

            {
                heading &&
                <div className={CSS.heading}>
                    <Pop><p className='fs-4 fw-500 c-secondary lh-1 caps'>{ heading }</p></Pop>
                </div>
            }
            
            <div className={CSS.slider}>
                <Splide hasTrack={false} aria-label="Attending airlines" options={{
                    type: 'loop',
                    pagination: false,
                    arrows: false,
                    autoplay: true,
                    interval: 1500,
                    speed: 750,
                    pauseOnHover: false,
                    pauseOnFocus: false,
                    gap: 'var(--space-lg)',
                    perPage: 1,
                    perMove: 1
                }}>
                    <SplideTrack>
                        { sponsors.map( partner => {
                            return (
                                <SplideSlide key={partner.id}>
                                    <a href={`/partners/${partner.id}`} rel="noreferrer" aria-label="Partner logo">
                                        <div className={CSS.logo}>
                                            <div className={CSS.logoWrapper}>
                                                <Image 
                                                    src={partner.partnerLogo[0].url} 
                                                    
                                                    layout='fill'
                                                    alt=""
                                                />
                                            </div>
                                        </div>
                
                                    </a>
                                </SplideSlide>
                            )
                        })}
                    </SplideTrack>

                </Splide>
            </div>

        </section>
    )
}