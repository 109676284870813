import { Avatar } from '../avatar/avatar'

import { Pop, Fade } from '../../animation/animation'

import CSS from './quote.module.css'

import QuoteSVG from './img/icon-quote.svg'

export const Quote = ({ avatar, quote, name, title, decal }) => {
    return (
        <>
            {
                avatar &&
                <div className='mb-xs'><Pop inline><Avatar src={avatar.url} alt={`${avatar.alt ? avatar.alt : 'speaker photo'}`} /></Pop></div>
            }
            <Fade>
                <p className={`${CSS.quote} ${decal} lh-1`}>
                    <QuoteSVG className={CSS.openingQuote} />
                    { quote }
                </p>
                <p className='mt-xs'><span className="fw-600">{ name }</span>{ title && ','} <br/>{ title }</p>
            </Fade>
        </>
    )
}