import Image from 'next/image'

import { Fade } from '../../animation/animation'

import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide'
import '@splidejs/react-splide/css'

import CSS from './logo-carousel.module.css'

export const LogoCarousel = ({ sponsors, heading }) => {

    if ( !sponsors ) return 

    return ( 
        <section className={CSS.layout}>

            {
                heading && 
                <div className="pb-sm">
                    <Fade>
                        <p className={`fs-1 c-secondary lh-1 caps center`}>{ heading }</p>
                    </Fade>
                </div>
            }
            
            <div className="center">
                <Splide hasTrack={false} aria-label="Attending airlines" options={{
                    type: 'loop',
                    pagination: false,
                    arrows: false,
                    autoplay: true,
                    interval: 1500,
                    speed: 750,
                    pauseOnHover: false,
                    pauseOnFocus: false,
                    gap: 'var(--space-lg)',
                    perPage: 5,
                    perMove: 1,
                    breakpoints: {
                        600: {
                            gap: '1rem',
                            perPage: 2
                        },
                        900: {
                            gap: '1rem',
                            perPage: 3
                        },
                        1200: {
                            gap: '1rem',
                            perPage: 4
                        }
                    }
                }}>
                    <SplideTrack>
                        { sponsors.map( partner => {
                            if ( !partner.partnerLogo.length ) return

                            return (
                                <SplideSlide key={partner.id}>
                                    <a href={`/partners/${partner.id}`} rel="noreferrer" aria-label="Partner logo">
                                        <div className={`${CSS.logo} bg-wash`}>
                                            <Image 
                                                src={partner.partnerLogo[0].url} 
                                                width={500}
                                                height={400}
                                                layout='responsive'
                                                alt=""
                                            />
                                        </div>
                
                                    </a>
                                </SplideSlide>
                            )
                        })}
                    </SplideTrack>

                </Splide>
            </div>

        </section>
    )
}