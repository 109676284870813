import { Pop, Fade, Slide } from '../../animation/animation'

import CSS from './impact-figure.module.css'

export const ImpactFigure = ({ figure, caption }) => {
    return (
        <div className={CSS.container}>
            <Pop><span className={`fs-xl c-primary`}>{ figure }</span></Pop>
            <Slide delay={.5}><span className={`fw-500 caps c-secondary`}>{ caption }</span></Slide>
        </div>
    )
}